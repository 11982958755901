import { logout } from '@/api/index'

const state = {
    name: '',
    avatar: '',
    roles: [],
    info: null
}

const mutations = {
    SET_INFO: (state, info) => {
        state.info = info
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles
    }
}

const actions = {
    login ({ commit }, userInfo) {
        return new Promise((resolve) => {
            const roles = []
            const name = userInfo.name || userInfo.mobile
            // 设置用户角色
            commit('SET_ROLES', roles)
            commit('SET_INFO', userInfo)
            commit('SET_NAME', name)
            commit('SET_AVATAR', userInfo.avatarUrl)
            resolve()
        })
    },
    logout () {
        return new Promise((resolve, reject) => {
            logout().then((res) => {
                window.location.href = `${res.data}${location.pathname}`
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
