import Vue from 'vue'
import App from './App.vue'
import * as filters from './filters'   // global filters
import router from './router/index'
import axios from "./api/http";
import store from './store'
import '@/styles/common.scss'

Vue.prototype.$http = axios;
Vue.config.productionTip = false;

// import './permission' // permission control

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
