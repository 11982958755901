/**
 * 保留小数位
 * @param {val} 要过滤的数据
 * @param {acc} 保留几个小数位
 */
export function toFixed(val, acc){
    let num = parseFloat(val);
    if (isNaN(num)) {
        num = 0;
    }
    let accuracy = parseInt(acc);
    if (isNaN(accuracy) || accuracy < 0 || accuracy > 10) {
        accuracy = 2
    }
    return num.toFixed(accuracy);
}

/**
 * 小数转百分比
 * @param {val} 原数据
 * @param {acc} 保留小数位
 */
export function toPercent(val, acc) {
    let num = parseFloat(val);
    if (isNaN(num)) {
        num = 0;
    }
    let accuracy = parseInt(acc);
    if (isNaN(accuracy) || accuracy < 0 || accuracy > 10) {
        accuracy = 2;
    }
    return (num * 100).toFixed(accuracy) + '%';
}

/**
 * 日期格式化
 * @param {number} date
*/
export function dateTimeFormat (date, fmt = 'yyyy-MM-dd HH:mm:ss'){
    if (!date) {
        return ''
    }
    if (typeof date === 'string') {
        date = date.replace('T', ' ').replace('Z', '');
        date = new Date(date.replace(/-/g, '/'))
    }
    if (typeof date === 'number') {
        date = new Date(date)
    }
    var o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours() % 12 === 0 ? 12 : date.getHours() % 12,
        'H+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
        'q+': Math.floor((date.getMonth() + 3) / 3),
        'S': date.getMilliseconds()
    }
    var week = {
        '0': '\u65e5',
        '1': '\u4e00',
        '2': '\u4e8c',
        '3': '\u4e09',
        '4': '\u56db',
        '5': '\u4e94',
        '6': '\u516d'
    }
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    if (/(E+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? '\u661f\u671f' : '\u5468') : '') + week[date.getDay() + ''])
    }
    for (var k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
        }
    }
    return fmt
}
