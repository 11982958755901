<template>
<div class="content">
    <div class="bottom">
        <span>©南京知行慧芯科技有限公司版权所有 <a href="https://beian.miit.gov.cn/" class="hyperlink">苏ICP备2021014413号-1</a>  地址：南京市玄武区珠江路88号1幢1411室</span>
    </div>
    </div>
</template>
<style scoped>
      * {
    margin: 0;
    padding: 0;
  }
  .content{
    width: 100%;
    margin: 0 auto;
    min-width: 1200px;
    max-width: 1920px;
   
}
.bottom{
    width: 100%;
height: 100px;
background: #000000;
display: flex;
justify-content: center;
align-items: center;
text-align: center;
    
}
.bottom span{
height: 25px;
font-size: 16px;
font-family: PingFangSC-Regular, PingFang SC ,PingFang-SC-Regular;
font-weight: 400;
color: #FFFFFF;
opacity: 0.7;
line-height: 25px;

        

}

a{
    color: #FFFFFF;

   text-decoration: none;
   margin-left: 5px;
   margin-right: 5px;
}

</style>
<script>
import '@/assets/font/PingFang-SC-Regular.css'
export default {
    
}
</script>