import Vue from 'vue';
import VueRouter from 'vue-router';


Vue.use(VueRouter);

export const constantRoutes = [
    {
        path: '/',
        redirect: '/home',
        
    },
    {
        path:'/Coursecenter',
        component:()=>import('@/views/Coursecenter/center.vue'),
        meta: {
            keepAlive: true//导航在该页面显示
          }
       
    },
    {
        path:'/home',
        component:()=>import('@/views/index/shouye.vue'),
        meta: {
            keepAlive: true//导航在该页面显示
          }
        
    },
     {
        path:'/sypt',
        component:()=>import('@/views/sypt/shiyanpingtai.vue'),
        meta: {
            keepAlive: true//导航在该页面显示
          }
        
    },
    {
        path:'/huixin/:id/huixinDetail',
        component:()=>import('@/views/sypt/detail.vue'),
        
    },
    {
        path:'/about',
        component:()=>import('@/views/about/about.vue'),
        meta: {
            keepAlive: true//导航在该页面显示
          }
        
    },
    {
        path: '/courseSquare',
        component:()=>import('@/views/courseSquare/index.vue'),
        meta: {
            keepAlive: true//导航在该页面显示
          }
    },
    {
        path: '/huixincourse/:id(\\d+)',
        component: () => import('@/views/courseDetail/index.vue')
    },
    {
        path: '/study/:id(\\d+)',
        component: () => import('@/views/study/index.vue')
    },
    { 
        path: '/404',
        component: () => import('@/views/error-page/404'),
        meta: {
            keepAlive: true//导航在该页面显示
          }
    },
    {
        path: '/401',
        component: () => import('@/views/error-page/401'),
        meta: {
            keepAlive: true//导航在该页面显示
          }
    }
];

const createRouter = () => new VueRouter({
    routes: [...constantRoutes]
})
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};
const router = createRouter();


export default router


