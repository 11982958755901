import axios from 'axios'

const devBaseApi = 'https://gateway.gaoxiaobang.com/labstudio'
const proBaseApi = 'https://gateway.gaoxiaobang.com/labstudio'

const service = axios.create({
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    },
    timeout: 5000
})
// 接口前缀设置
service.defaults.baseURL = process.env.NODE_ENV == 'development' ? devBaseApi : proBaseApi

// 请求拦截器
service.interceptors.request.use(
    config => {
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    response => {
        if (response && response.data) {
            return response.data
        } else {
            return response
        }

    },
    error => {
        return Promise.reject(error)
    }
)

export default service
