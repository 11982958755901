import request from "@/utils/request"

// 根据学生ID来获取学生所参与的项目列表
export function getProjectListByUserId () {
    return request({
        url: `/projectList`,
        method: 'get'
    })
}

export function getInfo () {
    return request({
        url: `https://gateway.gaoxiaobang.com/user_info/status/api`,
        method: 'get'
    })
}

export function logout () {
    return request({
        url: `https://gateway.gaoxiaobang.com/logout/api?service=${encodeURIComponent(window.location.origin)}`,
        method: 'get'
    })
}
