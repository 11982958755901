<template>
  <div class="content">
    <header>
      <div class="up">
        <div class="logo">
          <img src="~@/assets/images/logo.png" alt="" />
        </div>
        <ul class="nav">
          <li :class="{ change: bool }" @click="gotoHome">
            <a href="javascript:;">首页</a>
          </li>
          <li :class="{ change: isactive }" @click="gotoSypt">
            <a href="javascript:;">实验平台</a>
          </li>

          <li @click="gotoCenter" :class="{ changebg: isbool }">
            <a href="javascript:;">课程中心</a>
          </li>
          <li @click="gotoAbout" :class="{ aboutclass: abo }">
            <a href="javascript:;">关于我们</a>
          </li>
        </ul>
        <div class="up_right">
          <div class="up_word" v-show="!name">
            <a href="javascript:void(0)" @click="login">登录/注册 </a>
          </div>
          <div class="user" v-show="name">
            <img :src="avatar" /><span class="name">{{ name }}</span>
            <span class="down-arrow"></span>
            <div class="dropdown">
              <span @click="logout">退出</span>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="right-nav" v-if="$route.meta.keepAlive">
    <div class="icon">
        <span class="rgfw" @click="goOpenNav(1)"></span>
        <span class="zhsq" @click="goOpenNav(2)"></span>
        <span class="wtfk" @click="goOpenNav(3)"></span>
    </div>
    </div>
    <div class="rgfw-mask" v-show="isOpenMask">
        <div class="dialog">
            <div class="top">
                <div class="top-word">
                    <span>人工服务</span>
                        <span style="cursor: pointer;" @click="goCloseDialog()"><img src="~@/assets/img_index/images/applyclose.svg" alt=""></span>
                    </div>  
            </div>
            <ul class="center">
                <li>
                    <div class="center-word"><span>开通实验环境体验账号</span></div>
                    <dl class="main">
                    <dd style="margin-top: 24px;">
                        <img src="~@/assets/img_index/images/dialog-icon1.svg" alt="">
                        <span style="margin-left: 12px;">姓名：殷老师</span>
                    </dd>
                    <dd>
                        <img src="~@/assets/img_index/images/dialog-icon2.svg" alt="">
                        <span>微信：yin_guosen  </span>
                    </dd>
                     <dd>
                        <img src="~@/assets/img_index/images/dialog-icon3.svg" alt="">
                        <span>手机：15850695825</span>
                    </dd>
                    </dl>
                </li>
                <li >
                    <div class="center-word"><span>实验平台合作</span></div>
                    <dl class="main">
                      <dd style="margin-top: 24px;">
                        <img src="~@/assets/img_index/images/dialog-icon1.svg" alt="">
                        <span style="margin-left: 12px;">姓名：殷老师</span>
                    </dd>
                    <dd>
                        <img src="~@/assets/img_index/images/dialog-icon2.svg" alt="">
                        <span>微信：yin_guosen  </span>
                    </dd>
                     <dd>
                        <img src="~@/assets/img_index/images/dialog-icon3.svg" alt="">
                        <span>手机：15850695825</span>
                    </dd>
                    </dl>
                </li>
            </ul>
        </div>
    </div>
  </div>
</template>

<script>
  import '@/assets/font/PingFang-SC-Regular.css';
  import { goCas } from '@/utils/auth';
  import { getInfo } from '@/api/index';
  import { mapGetters } from 'vuex';
  export default {
    data() {
      return {
          isOpenMask:false,
        bool: false,
        isbool: false,
        url: ['/home', '/Coursecenter', '/sypt'],
        isactive: false,
        abo: false,
      };
    },
    methods: {
      gotoCenter() {
        this.$router.push('/courseSquare');
      },
      gotoHome() {
        this.$router.push('/home');
      },
      gotoSypt() {
        this.$router.push('/sypt');
      },
      login() {
        goCas();
      },
      logout() {
        this.$store.dispatch('user/logout');
      },
      gotoAbout() {
        this.$router.push('/about');
      },
      goOpenNav(number){
          if(number!='1'){
        this.isOpenMask=false
          var newWin = window.open('', '_blank');
          }else{
              this.isOpenMask=true
              
          }
           
          
           
           switch (number) {
               
               case 2:
                    newWin.location.href = 'https://www.wjx.top/vm/wzozkxN.aspx'
                   break;
               case 3:
                    newWin.location.href = 'https://www.wjx.top/vm/QNSZiI6.aspx'
                   break;
               default:
                   break;
           }
      },
      goCloseDialog(){
          this.isOpenMask=false
      }

    },
    computed: {
      ...mapGetters(['name', 'avatar']),
    },
    created() {
      let vm = this;
      if (vm.name) {
        console.log(vm.name);
      } else {
        getInfo().then((res) => {
          console.log(res.status);
          if (res.status && res.data) {
            // store user info
            vm.$store
              .dispatch('user/login', res.data)
              .then(() => {
                console.log(vm.$store.state.user.name);
              })
              .catch(() => {});
          }
        });
      }
    },
    watch: {
      $route: function(val) {
        if (val.path === '/home') {
          this.bool = true;
          this.isbool = false;
          this.isactive = false;
          this.abo = false;
        }
        if (val.path === '/courseSquare') {
          this.bool = false;
          this.isbool = true;
          this.isactive = false;
          this.abo = false;
        }
        if (val.path === '/sypt') {
          this.bool = false;
          this.isbool = false;
          this.isactive = true;
          this.abo = false;
        }
        if (val.path === '/about') {
          this.bool = false;
          this.isbool = false;
          this.isactive = false;
          this.abo = true;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  * {
    margin: 0;
    padding: 0;
  }
    .right-nav{
        height: 243px;
        width: 50px;
        position: fixed;
        top: 167px;
        right: 0px;
        z-index: 1;
        .icon{
            span{
                display: block;
                height: 57px;
                width: 50px;
                cursor: pointer;
                margin-top: 24px;
            }
            /* .rgfw{
                background: url('../../assets/img_index/images/nav-icon1.svg') no-repeat;
                background-size: cover;               
            } */
            .rgfw{
                background: url('../../assets/img_index/images/nav-icon4.svg') no-repeat;
                background-size: cover;   
            }
            /* .zhsq{
                 background: url('../../assets/img_index/images/nav-icon2.svg') no-repeat;
                background-size: cover;
            } */
            .zhsq{
                background: url('../../assets/img_index/images/nav-icon5.svg') no-repeat;
                background-size: cover;   
            }
            /* .wtfk{
                 background: url('../../assets/img_index/images/nav-icon3.svg') no-repeat;
                background-size: cover;
            } */
            .wtfk{
                background: url('../../assets/img_index/images/nav-icon6.svg') no-repeat;
                background-size: cover;   
            }
        }
    }

  .user {
    padding-top: 12px;
    position: relative;
    cursor: pointer;
    &:hover {
      .dropdown {
        display: block;
      }
    }
    .name {
      color: white;
      font-size: 16px;
      line-height: 36px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 9px;
    }
    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      margin-right: 8px;
      display: inline-block;
      vertical-align: middle;
    }
    .down-arrow {
      width: 16px;
      position: relative;
      display: inline-block;
      height: 20px;
      vertical-align: middle;

      &:before {
        position: absolute;
        width: 9px;
        display: block;
        content: '';
        height: 9px;
        border-left: 1px solid rgba(255, 255, 255, 0.5);
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        transform: rotate(-45deg);
      }
    }
    .dropdown {
      display: none;
      position: absolute;
      width: 80px;
      top: 60px;
      font-size: 16px;
      text-align: center;
      height: 26px;
      background: #fff;
      border-radius: 3px;
      padding-top: 8px;
      padding-bottom: 8px;
      right: 0;
      z-index: 9999;
      cursor: pointer;
      span {
        display: block;
        height: 26px;
        line-height: 26px;
        &:hover {
          color: #231dff;
        }
      }
      &:before {
        position: absolute;
        display: block;
        content: '';
        width: 9px;
        height: 9px;
        background: #fff;
        transform: rotate(45deg);
        top: -5px;
        left: 50px;
      }
    }
  }
  .change,
  .changebg,
  .aboutclass {
    background: #0700ff;
  }
  .content {
    width: 100%;
    margin: 0 auto;
    min-width: 1200px;
    max-width: 1920px;
  }
  header {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    background: #000000;
  }
  .up {
    width: 1200px;
    height: 60px;
    display: flex;
  }
  .logo {
    width: 253px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nav {
    width: 700px;
    height: 60px;
    display: flex;
  }
  .nav li {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nav li a {
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC, PingFang-SC-Regular;
    font-weight: 400;
    line-height: 25px;
    opacity: 0.6;
    text-decoration: none;
    color: #fefefe;
  }
  .nav li:hover {
    background: #0700ff;
    color: #fefefe;
    cursor: pointer;
    opacity: 1 !important;
  }
  .ts:hover {
    background: #000 !important;
  }
  .up_right {
    width: 247px;
    height: 100%;
    display: flex;
    margin-left: 20px;
  }
  .up_word {
    height: 60px;
    display: flex;
    margin-left: auto;
    align-items: center;
  }
  .up_word a {
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC, PingFang-SC-Regular;
    font-weight: 400;
    color: #fefefe;
    line-height: 25px;
    text-decoration: none;
    opacity: 0.6;
  }
  .up_word:hover {
    background: #0700ff;
    color: #fefefe;
    cursor: pointer;
    opacity: 1 !important;
  }

  .rgfw-mask{
      display:flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 33333;
    
    top: 0px;
    .dialog{
     width: 420px;
      height: 520px;
      background: #FFFFFF;
      .top{
          height: 60px;
          width: 420px;
          background: #231DFF;
          display: flex;
          justify-content: center;
          .top-word{
              width: 372px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              span{
font-size: 18px;
font-weight: 500;
color: #FFFFFF;
              }

          }
      }
      .center{
          width: 312px;
          margin: 8px auto 48px auto;
          
          li{
              list-style: none;
              width: 312px;
              margin-top: 24px;
border: 1px solid #F0F2F6;
              .center-word{
                   width: 312px;
              height: 46px;
              background: #F0F2F6;
                span{
                font-size: 18px;
                font-weight: 600;
                color: #231DFF;
                margin-left: 34px;
                display: inline-block;
                margin-top: 11px;
                }
              }
              .main{
                  
                  height: 116px;
                  dd{
                      margin-top: 10px;
                      height: 22px;
                      display: flex;
                      align-items: center;
                      img{
                          margin-left: 34px;

                      }
                      span{
                        font-size: 16px;
                        color: #333333;
                        margin-left: 11px;
                      }
                  }
              }
          }
      }
    }

      

  }
</style>
