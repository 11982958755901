<template>
  <div id="app">
    <Header></Header>
   
    <router-view></router-view>

  
   <Footer></Footer>
  </div>
</template>

<script>

import Footer from "@/components/Navigation/Footer";
import Header from "@/components/Navigation/Header";



export default {
  name: 'App',
  components: {
    Footer,
    Header,
   
  }
}
</script>

<style lang="scss">
 #app{
   .red{
     color:red;
   }
   font-family: PingFangSC-Regular,PingFang SC;
 }
 *{
   margin: 0;
   padding: 0;
 }
</style>
